import { useEffect, useState } from 'react';
import './global.css';
import ViewMeditations from './components/view-meditations';
import ViewCourses from './components/view-courses';
import NavBar from './components/navigation';
import LoginPage from './components/login-page';
import LogoutPage from './components/logout-page';
import { PAGES } from './model/pages';
import { onAuthStateChanged } from 'firebase/auth';
import { auth, logout } from './model/firebase';
import ViewMessage from './components/message';
import { useAuth } from './context/auth-context';

function App() {
  const { user, setUser, setAccessToken } = useAuth();
  const [page, setPage] = useState(PAGES.ViewMeditations);
  const [message, setMessage] = useState("");
  const [hasLoggedOut, setHasLoggedOut] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (!currentUser && !hasLoggedOut) {
        setPage(PAGES.LoginPage);
      } else if (!currentUser && hasLoggedOut) {
        setPage(PAGES.LogoutPage);
      } else if(currentUser) {
        setPage(PAGES.ViewMeditations);
      }
      setAccessToken(currentUser?.accessToken);
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const onLogout = () => {
    setHasLoggedOut(true);
    logout();
  }

  return (
    <div className="App">
      {![PAGES.LoginPage, PAGES.LogoutPage].includes(page) ? (
        <NavBar page={page} setPage={(_page) => setPage(_page)} onLogout={onLogout}/>
      ) : null}
      {message ? <ViewMessage message={message} /> : null}
      {page === PAGES.LoginPage ? <LoginPage /> : null}
      {page === PAGES.LogoutPage ? <LogoutPage /> : null}
      {user && page === PAGES.ViewMeditations ? <ViewMeditations /> : null}
      {user && page === PAGES.ViewCourses ? <ViewCourses /> : null}
    </div>
  );

}

export default App;
