import { Modal, Button } from 'react-bootstrap';

export default function ConfirmModal({ message, onConfirm, onCancel }) {
    return (
        <Modal show={true} onHide={onCancel}>
            <Modal.Body>
                { message }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={onCancel}>Cancel</Button>
                <Button variant="primary"onClick={onConfirm}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    )
}