const baseUrl = process.env.REACT_APP_BASE_URL;

export const upload = (formData, type, accessToken) => {
    return fetch(`${baseUrl}/upload`, {
        method: "POST",
        headers: { 
            "Authorization": `Bearer ${accessToken}`,
            "type": type
        },
        body: formData
    })
    .then(response => response.json());
}

export const getFileUrl = (type, filename, accessToken) => {
    return fetch(`${baseUrl}/upload/item-url`, {
        method: "POST",
        headers: { 
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({type, filename})
    })
    .then(response => response.text());
}