import { useAuth } from "../context/auth-context";
import { useEffect, useState } from "react";
import { getFileUrl } from "../api/shared-actions";

export default function AudioViewer({filename, onRemove}) {
    const { accessToken } = useAuth();
    const [audioUrl, setAudioUrl] = useState("");

    useEffect(() => {
        getFileUrl("audio", filename, accessToken)
            .then(url => setAudioUrl(url));
    }, [filename]);

    return (
        <div>
            <div className="d-flex justify-content-between mb-3">
                Audio  { onRemove ? <span className="badge text-bg-danger clickable" onClick={onRemove}>x Remove</span> : null }
            </div>
            <div className="text-center mb-3">
                { audioUrl ? (
                    <audio controls>
                        <source src={audioUrl} style={{width: "400px"}} />
                    </audio>
                ) : <div>Loading</div>} 
            </div>
        </div>
    );
}