import { PAGES } from "../model/pages";

export default function NavBar({ page, setPage }) {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">Dieper</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item" onClick={() => setPage(PAGES.ViewMeditations)}>
                        <a className={"nav-link clickable " + (page == PAGES.ViewMeditations ? "active" : "")}>Meditations</a>
                    </li>
                    <li className="nav-item" onClick={() => setPage(PAGES.ViewCourses)}>
                        <a className={"nav-link clickable " + (page == PAGES.ViewCourses ? "active" : "")}>Courses</a>
                    </li>
                </ul>
                <form className="d-flex">
                    <button id="logout-button" className="btn btn-outline-warning">Logout</button>
                </form>
                </div>
            </div>
        </nav>
    );
}