import { Image } from "react-bootstrap";
import { useAuth } from "../context/auth-context";
import { useEffect, useState } from "react";
import { getFileUrl } from "../api/shared-actions";

export default function ImageViewer({filename, onRemove}) {
    const { accessToken } = useAuth();
    const [imageUrl, setImageUrl] = useState("");

    useEffect(() => {
        getFileUrl("images", filename, accessToken)
            .then(url => setImageUrl(url));
    }, [filename]);

    return (
        <div>
            <div className="d-flex justify-content-between mb-3">
                Image  { onRemove ? <span className="badge text-bg-danger clickable" onClick={onRemove}>x Remove</span> : null }
            </div>
            <div className="text-center mb-3">
                { imageUrl ? <Image src={imageUrl} style={{width: "400px"}} /> : <div>Loading</div>} 
            </div>
        </div>
    );
}