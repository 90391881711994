import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useAuth } from "../context/auth-context";
import { upload } from "../api/shared-actions";
import { createCourse, updateCourse } from "../api/course-actions";
import ImageViewer from "./image-viewer";


export default function EditCourses({ item, onClose, onSave }) {
    const {accessToken} = useAuth();
    const [message, setMessage] = useState();
    const [title, setTitle] = useState(item?.title);
    const [author, setAuthor] = useState(item?.author);
    const [description, setDescription] = useState(item?.description);
    const [category, setCategory] = useState(item?.category);
    const [tag, setTag] = useState("")
    const [tags, setTags] = useState(item?.tags ?? [])
    const [isPaid, setIsPaid] = useState(item?.isPaid);
    const [color, setColor] = useState(item?.color);
    const [imageFilename, setImageFilename] = useState(item?.imageFilename);
    const [duration, setDuration] = useState(item?.duration);
    const [file, setFile] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState();
    
    const addTag = () => {
        if(tag?.length) {
            setTags([ ...tags.filter(_t => _t != tag), tag ]);
        }
        setTag("");
    }

    const removeTag = (t) => {
        setTags([ ...tags.filter(_t => _t != t) ]);
    }

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    }

    const onUpload = () => {
        if(!file) {
            return;
        }
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", "images");

        setLoading(true);
        upload(formData, "images", accessToken)
            .then(result => {
                setImageFilename(result.filename);
                setLoading(false);
            })
            .catch(err => {
                setErrors({ imageFilename: "Failed to upload, please try again." });
                setLoading(false);
            })
    }

    const validateAndSave = () => {
        const newItem = {
            title, 
            author, 
            description, 
            category, 
            tags, 
            isPaid, 
            color, 
            imageFilename, 
            duration
        };

        console.log(newItem)
        const errors = {};

        if(!title) {
            errors.title = "Please enter a title";
        }
        if(!author) {
            errors.author = "Please enter an author";
        }
        if(!description) {
            errors.description = "Please enter a description";
        }
        if(!category) {
            errors.category = "Please enter a category";
        }
        if(!imageFilename) {
            errors.imageFilename = "Please upload a file";
        }
        if(!duration) {
            errors.duration = "Please enter how many items there will be in the course";
        }

        setErrors(errors);

        if(Object.keys(errors).length == 0) {
            if(item._id) {
                newItem._id = item._id;
                updateCourse(newItem, accessToken)
                    .then(result => {
                        onSave({ ...newItem, uploadDate: (new Date()).toISOString(), _id: result.id });
                    })
            } else {
                createCourse(newItem, accessToken)
                    .then(result => {
                        onSave(newItem);
                    })
            }
        }
    }

    return (
        <Modal show={true} onClose={onClose}>
            <Modal.Header>
                <h4>{item._id ? "Edit" : "Create"} course</h4>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Enter course title" 
                        value={title} 
                        isInvalid={!!errors?.title}
                        onChange={e => setTitle(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.title}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Author</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Enter course author" 
                        value={author}
                        isInvalid={!!errors?.author}
                        onChange={e => setAuthor(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.author}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Enter course description" 
                        value={description} 
                        isInvalid={!!errors?.description}
                        onChange={e => setDescription(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.description}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Category</Form.Label>
                    <Form.Select 
                        type="select" 
                        placeholder="Select course category" 
                        value={category} 
                        isInvalid={!!errors?.category}
                        onChange={e => setCategory(e.target.value)}>
                            <option>Please select...</option>
                            <option value={"Adults"}>Adults</option>
                            <option value={"Kids"}>Kids</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {errors.category}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Check 
                        type="switch" 
                        checked={isPaid} 
                        label="Is Paid Content" 
                        onChange={e => setIsPaid(e.target.checked)}/>
                </Form.Group>
                <hr />
                <Form.Group className="mb-3">
                    <Form.Label>Add tags</Form.Label>
                    <div className="mt-2 mb-2">
                        { tags?.map(tag => (
                            <span className="badge text-bg-primary clickable" onClick={() => removeTag(tag)}>- {tag}</span>
                        )) }
                    </div>
                    <Row>
                        <Col xs={9}>
                            <Form.Control 
                                type="text"
                                placeholder="Enter tag to add" 
                                value={tag}
                                onKeyDown={e => e.key == "Enter" ? addTag() : () => {}}
                                onChange={e => setTag(e.target.value)}/>
                        </Col>
                        <Col xs={3} >
                            <Button variant="primary" className="w-100" onClick={() => addTag()}>+ Add</Button>
                        </Col>
                    </Row>
                </Form.Group>
                <hr />
                <Form.Group className="mb-3">
                    <Form.Label>Color</Form.Label>
                    <Form.Control type="color" value={color} onChange={e => setColor(e.target.value)}/>
                </Form.Group>
                <hr />
                { imageFilename ? <ImageViewer filename={imageFilename} onRemove={() => setImageFilename(null)}/> : <>
                    <Form.Group className="mb-3">
                        <Form.Label>Upload image file</Form.Label>
                        <Form.Control type="file" isInvalid={!!errors.imageFilename} onChange={handleFileChange} />
                        <Form.Control.Feedback type="invalid">
                            {errors.imageFilename}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div style={{textAlign: "right"}} className="mb-2">
                        { !loading ? <Button variant="primary" disabled={!file} onClick={onUpload}>Upload</Button> : <div>Loading...</div> }
                    </div>
                </> }
                <hr />
                <Form.Group className="mb-3">
                    <Form.Label>Number of items in course</Form.Label>
                    <Form.Control 
                        type="number" 
                        placeholder="Enter the number of items" 
                        value={duration} 
                        isInvalid={!!errors?.duration}
                        onChange={e => setDuration(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.duration}
                    </Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={onClose}>Close</Button>
                <Button variant="primary" onClick={validateAndSave}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
}