const baseUrl = process.env.REACT_APP_BASE_URL;

export const loadCourses = (accessToken) => {
    return fetch(`${baseUrl}/courses/list`, {
        headers: { "Authorization": `Bearer ${accessToken}`}
    })
    .then(response => response.json());
}

export const deleteCourse = (id, accessToken) => {
    return fetch(`${baseUrl}/courses/${id}`, {
        method: "DELETE",
        headers: { "Authorization": `Bearer ${accessToken}`}
    })
    .then(response => response.json());
}

export const createCourse = (course, accessToken) => {
    return fetch(`${baseUrl}/courses/create`, {
        method: "POST",
        headers: { 
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(course)
    })
    .then(response => response.json());
}

export const updateCourse = (course, accessToken) => {
    return fetch(`${baseUrl}/courses/update`, {
        method: "POST",
        headers: { 
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(course)
    })
    .then(response => response.json());
}