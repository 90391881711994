import { useEffect, useState } from "react";
import EditCourses from "./edit-courses";
import { useAuth } from "../context/auth-context";
import { DeleteIcon, EditIcon } from "./icons";
import { deleteCourse, loadCourses } from "../api/course-actions";
import ConfirmModal from "./confirm-modal";
import { formatDate } from "../utils/utils";

const INITIAL_ITEM = {
    // uploadDate
    title: "",
    description: "",
    author: "",
    category: "Adults",
    tags: [],
    isPaid: true,
    color: "#4589BC",
    imageFilename: "",
    duration: 0
}

export default function ViewCourses() {
    const { accessToken } = useAuth();
    const [showEdit, setShowEdit] = useState(false);
    const [editItem, setEditItem] = useState();
    const [items, setItems] = useState();
    const [deleteId, setDeleteId] = useState();
    const [showCancelEdit, setShowCancelEdit] = useState(false);

    useEffect(() => {
        if(accessToken) {
            load();
        }
    }, [accessToken]);

    const load = () => {
        loadCourses(accessToken)
            .then(result => setItems(result?.items ?? []));
    }

    const onCreateNew = () => {
        setEditItem({ ...INITIAL_ITEM, tags: [] });
        setShowEdit(true);
    }

    const onEditItem = (item) => {
        setEditItem(item);
        setShowEdit(true);
    }

    const onDeleteItem = (item) => {
        setDeleteId(item._id);
    }

    const onConfirmDelete = () => {
        deleteCourse(deleteId, accessToken)
    }

    const onCloseEdit = () => {
        setShowEdit(false);
        setShowCancelEdit(true)
    }

    const onCloseConfirm = () => {
        setShowCancelEdit(false);
    }

    const onCloseCancel = () => {
        setShowEdit(true);
        setShowCancelEdit(false);
    }

    const onSave = (item) => {
        setShowEdit(false);
        setItems([...items.filter(i => i._id != item._id), item]);
    }

    return (
        <div className="container">
            { showEdit ? <EditCourses item={editItem} onSave={onSave} onClose={onCloseEdit}/> : null }
            { deleteId ? <ConfirmModal message="Are you sure you want to delete this item?" onConfirm={onConfirmDelete} onCancel={() => setDeleteId(null)} /> : null }
            { showCancelEdit ? <ConfirmModal message="Are you sure you want to stop editing this item? All changes will be lost." onConfirm={onCloseConfirm} onCancel={onCloseCancel} /> : null }
            <div style={{textAlign: "right"}}>
                <button className="btn btn-primary mb-3" onClick={onCreateNew}>+ New</button>
            </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    { items?.map(item => (
                        <tr>
                            <td>{formatDate(item.uploadDate)}</td>
                            <td>{item.title}</td>
                            <td>{item.author}</td>
                            <td>{item.description}</td>
                            <td>
                                <button className="btn" onClick={() => onEditItem(item)}><EditIcon /></button>
                                <button className="btn" onClick={() => onDeleteItem(item)}><DeleteIcon /></button>
                            </td>
                        </tr>
                    )) }
                </tbody>
            </table>
        </div>
    );
}