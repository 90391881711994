import { useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useAuth } from "../context/auth-context";
import { upload } from "../api/shared-actions";
import ImageViewer from "./image-viewer";
import AudioViewer from "./audio-viewer";
import { createMeditation, updateMeditation } from "../api/meditation-actions";

export default function EditMeditations({ item, onClose, onSave }) {
    const {accessToken} = useAuth();
    const [message, setMessage] = useState();
    const [title, setTitle] = useState(item?.title);
    const [author, setAuthor] = useState(item?.author);
    const [description, setDescription] = useState(item?.description);
    const [category, setCategory] = useState(item?.category);
    const [tag, setTag] = useState("")
    const [tags, setTags] = useState(item?.tags ?? [])
    const [isPaid, setIsPaid] = useState(item?.isPaid);
    const [color, setColor] = useState(item?.color);
    const [imageFilename, setImageFilename] = useState(item?.imageFilename);
    const [audioFilename, setAudioFilename] = useState(item?.audioFilename);
    const [duration, setDuration] = useState(item?.duration);

    const [imageFile, setImageFile] = useState(null);
    const [audioFile, setAudioFile] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState();
    
    const addTag = () => {
        if(tag?.length) {
            setTags([ ...tags.filter(_t => _t != tag), tag ]);
        }
        setTag("");
    }

    const removeTag = (t) => {
        setTags([ ...tags.filter(_t => _t != t) ]);
    }

    const handleImageFileChange = (e) => {
        setImageFile(e.target.files[0]);
    }

    const handleAudioFileChange = (e) => {
        setAudioFile(e.target.files[0]);
    }

    const onUploadImage = () => {
        onUpload("images", imageFile, result => setImageFilename(result.filename));
    }

    const onUploadAudio = () => {
        onUpload("audio", audioFile, result => setAudioFilename(result.filename));
    }

    const onUpload = (type, file, onResult) => {
        if(!file) {
            return;
        }
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", type);

        setLoading(true);
        upload(formData, type, accessToken)
            .then(result => {
                onResult(result)
                setLoading(false);
            })
            .catch(err => {
                setErrors({ imageFilename: "Failed to upload, please try again." });
                setLoading(false);
            })
    }

    const validateAndSave = () => {
        const newItem = {
            title, 
            author, 
            description, 
            category, 
            tags, 
            isPaid, 
            color, 
            imageFilename,
            audioFilename, 
            duration
        };

        console.log(newItem)
        const errors = {};

        if(!title) {
            errors.title = "Please enter a title";
        }
        if(!author) {
            errors.author = "Please enter an author";
        }
        if(!description) {
            errors.description = "Please enter a description";
        }
        if(!category) {
            errors.category = "Please enter a category";
        }
        if(!imageFilename) {
            errors.imageFilename = "Please upload a image file";
        }
        if(!audioFilename) {
            errors.audioFilename = "Please upload a audio file";
        }
        if(!duration) {
            errors.duration = "Please enter the duration of the audio item";
        }

        setErrors(errors);

        if(Object.keys(errors).length == 0) {
            if(item._id) {
                newItem._id = item._id;
                updateMeditation(newItem, accessToken)
                    .then(result => {
                        onSave({ ...newItem, uploadDate: (new Date()).toISOString(), _id: result.id });
                    })
            } else {
                createMeditation(newItem, accessToken)
                    .then(result => {
                        onSave(newItem);
                    })
            }
        }
    }

    return (
        <Modal show={true} onClose={onClose}>
            <Modal.Header>
                <h4>{item._id ? "Edit" : "Create"} meditation</h4>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Enter meditation title" 
                        value={title} 
                        isInvalid={!!errors?.title}
                        onChange={e => setTitle(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.title}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Author</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Enter meditation author" 
                        value={author}
                        isInvalid={!!errors?.author}
                        onChange={e => setAuthor(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.author}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Enter meditation description" 
                        value={description} 
                        isInvalid={!!errors?.description}
                        onChange={e => setDescription(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.description}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>Category</Form.Label>
                    <Form.Select 
                        type="select" 
                        placeholder="Select meditation category" 
                        value={category} 
                        isInvalid={!!errors?.category}
                        onChange={e => setCategory(e.target.value)}>
                            <option>Please select...</option>
                            <option value={"Adults"}>Adults</option>
                            <option value={"Kids"}>Kids</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                        {errors.category}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Check 
                        type="switch" 
                        checked={isPaid} 
                        label="Is Paid Content" 
                        onChange={e => setIsPaid(e.target.checked)}/>
                </Form.Group>
                <hr />
                <Form.Group className="mb-3">
                    <Form.Label>Add tags</Form.Label>
                    <div className="mt-2 mb-2">
                        { tags?.map(tag => (
                            <span className="badge text-bg-primary clickable" onClick={() => removeTag(tag)}>- {tag}</span>
                        )) }
                    </div>
                    <Row>
                        <Col xs={9}>
                            <Form.Control 
                                type="text"
                                placeholder="Enter tag to add" 
                                value={tag}
                                onKeyDown={e => e.key == "Enter" ? addTag() : () => {}}
                                onChange={e => setTag(e.target.value)}/>
                        </Col>
                        <Col xs={3} >
                            <Button variant="primary" className="w-100" onClick={() => addTag()}>+ Add</Button>
                        </Col>
                    </Row>
                </Form.Group>
                <hr />
                <Form.Group className="mb-3">
                    <Form.Label>Color</Form.Label>
                    <Form.Control type="color" value={color} onChange={e => setColor(e.target.value)}/>
                </Form.Group>
                <hr />
                { imageFilename ? <ImageViewer filename={imageFilename} onRemove={() => setImageFilename(null)}/> : <>
                    <Form.Group className="mb-3">
                        <Form.Label>Upload image file</Form.Label>
                        <Form.Control type="file" isInvalid={!!errors.imageFilename} onChange={handleImageFileChange} />
                        <Form.Control.Feedback type="invalid">
                            {errors.imageFilename}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div style={{textAlign: "right"}} className="mb-2">
                        { !loading ? <Button variant="primary" disabled={!imageFile} onClick={onUploadImage}>Upload</Button> : <div>Loading...</div> }
                    </div>
                </> }
                <hr />
                { audioFilename ? <AudioViewer filename={audioFilename} onRemove={() => setAudioFilename(null)}/> : <>
                    <Form.Group className="mb-3">
                        <Form.Label>Upload audio file</Form.Label>
                        <Form.Control type="file" isInvalid={!!errors.audioFilename} onChange={handleAudioFileChange} />
                        <Form.Control.Feedback type="invalid">
                            {errors.audioFilename}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <div style={{textAlign: "right"}} className="mb-2">
                        { !loading ? <Button variant="primary" disabled={!audioFile} onClick={onUploadAudio}>Upload</Button> : <div>Loading...</div> }
                    </div>
                </> }
                <hr />
                <Form.Group className="mb-3">
                    <Form.Label>Approximate duration in minutes</Form.Label>
                    <Form.Control 
                        type="number" 
                        placeholder="Enter the duration in minutes" 
                        value={duration} 
                        isInvalid={!!errors?.duration}
                        onChange={e => setDuration(e.target.value)}/>
                    <Form.Control.Feedback type="invalid">
                        {errors.duration}
                    </Form.Control.Feedback>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={onClose}>Close</Button>
                <Button variant="primary" onClick={validateAndSave}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
}