const baseUrl = process.env.REACT_APP_BASE_URL;

export const loadMeditations = (accessToken) => {
    return fetch(`${baseUrl}/meditations/list`, {
        headers: { "Authorization": `Bearer ${accessToken}`}
    })
    .then(response => response.json());
}

export const deleteMeditation = (id, accessToken) => {
    return fetch(`${baseUrl}/meditations/${id}`, {
        method: "DELETE",
        headers: { "Authorization": `Bearer ${accessToken}`}
    })
    .then(response => response.json());
}

export const createMeditation = (meditation, accessToken) => {
    return fetch(`${baseUrl}/meditations/create`, {
        method: "POST",
        headers: { 
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(meditation)
    })
    .then(response => response.json());
}

export const updateMeditation = (meditation, accessToken) => {
    return fetch(`${baseUrl}/meditations/update`, {
        method: "POST",
        headers: { 
            "Authorization": `Bearer ${accessToken}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(meditation)
    })
    .then(response => response.json());
}