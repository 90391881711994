import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyApdnlwvPu3Or_OdFLD9Xiw6g8wEmLTI1U",
    authDomain: "dieperapp.firebaseapp.com",
    projectId: "dieperapp",
    storageBucket: "dieperapp.appspot.com",
    messagingSenderId: "247198472547",
    appId: "1:247198472547:web:ea25f4a6a328353f4f5b56",
    measurementId: "G-G8TB2PTD87"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

export function login(username, password, cb) {
    signInWithEmailAndPassword(auth, username, password)
    .then((userCredential) => {
        cb(null, userCredential);
    })
    .catch((error) => {
        cb({ code: error.code, message: error.message }, null);
    });
}

export function logout() {
    signOut(auth);
}
